import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonServices } from 'src/app/services/comon.service';

@Injectable({
    providedIn: 'root'
})
export class PsetrainingService {

    constructor(
        private service: CommonServices,
    ) { }

  
    
    public async Get_Pse(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '9'
                },{
                    "columnName": "sectionid",
                    "columnValue": '40'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }

    public async Get_guide(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '9'
                },{
                    "columnName": "sectionid",
                    "columnValue": '41'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }
    public async Get_Webnar(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '9'
                },{
                    "columnName": "sectionid",
                    "columnValue": '42'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }


    public async Get_surveydate(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/Survey?page=1&itemsPerPage=10`;
                this.service.Http.get(url).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }
    // https://api.psereadi.org/v1/api/Survey?page=1&itemsPerPage=10


   

}