import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BcopService {

  constructor(private http:HttpClient) {


   }

  data(){
    const body={
      "condition": "Get_CMSPageData",
      "pageid": localStorage.getItem('id')

      }
      console.log(body)
    return this.http.post<any>(`${environment.ServiceUrl}/SP/GetCMSContent`,body)
  }
  // token(){
  //   let body={
  //     "username": "Bcop_admin",
  //     "password": "Bcop_admin123"
  //   }
  //   return this.http.post<any>('https://bcopapi.meanhost.in/v1/api/Token',body).subscribe(r=>{
  //     console.log(r)
  //     localStorage.setItem('token',r.document.accessToken)
  //   })
  // }



//   public async token(): Promise<any> {
//     try {
//         return new Promise((resolve, reject) => {
//             const url = `https://bcopapi.meanhost.in/v1/api/Token`;
//             let body={
//               "username": "Bcop_admin",
//               "password": "Bcop_admin123"
//             }
//             this.http.post(url, body).pipe(
//             ).subscribe((r: any) => resolve(r), reject);
//         });
//     } catch (error) {
//         console.log(error);
//     }
// }

//   get_token(){
//     return localStorage.getItem('token')
//   }
}
