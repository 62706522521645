import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonServices } from 'src/app/services/comon.service';


@Injectable({
    providedIn: 'root'
})
export class SignupService {

    constructor(
        private service: CommonServices,
    ) { }



    public async Register(body): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/Member`;

                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                   // map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }





   public async get_States(): Promise<any> {
    try {
        return new Promise((resolve, reject) => {
            const url = `${this.service.serviceUrl}/State`;
            // const url = `${this.service.serviceUrl}/Bcopstate?page=1&itemsPerPage=100`;

            this.service.Http.get(url).pipe(
                // tap(d => console.log('test',d)),
               // map((d: any) => (d.code === 1 ? d.document.records : false))
            ).subscribe((r: any) => resolve(r), reject);
        });
    } catch (error) {
        console.log(error);
    }
}
public async Bcopget_States(): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          //const url = `${this.service.serviceUrl}/State`;
           const url = `${this.service.serviceUrl}/Bcopstate?page=1&itemsPerPage=100`;

          this.service.Http.get(url).pipe(
              // tap(d => console.log('test',d)),
             // map((d: any) => (d.code === 1 ? d.document.records : false))
          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}



public async get_Counties(body): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          const url = `${this.service.serviceUrl}/County/filter?andOr=OR&page=1&itemsPerPage=5000`;
          this.service.Http.post(url,body).pipe(
              // tap(d => console.log('test',d)),
             // map((d: any) => (d.code === 1 ? d.document.records : false))
          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}

public async get_Zipcodes(body): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          const url = `${this.service.serviceUrl}/Uszips/filter?andOr=or&page=1&itemsPerPage=50000`;
          this.service.Http.post(url,body).pipe(

          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}
public async get_Zipcodes_all(): Promise<any> {
  try {
      return new Promise((resolve, reject) => {
          const url = `${this.service.serviceUrl}/Uszips?page=1&itemsPerPage=100`;
          this.service.Http.get(url).pipe(

          ).subscribe((r: any) => resolve(r), reject);
      });
  } catch (error) {
      console.log(error);
  }
}


}
