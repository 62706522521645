import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonServices } from 'src/app/services/comon.service';

@Injectable({
    providedIn: 'root'
})
export class HealthCareService {

    constructor(
        private service: CommonServices,
    ) { }

  

    public async Get_Health(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '17'
                },{
                    "columnName": "sectionid",
                    "columnValue": '58'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }
    public async Get_Health_food(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '18'
                },{
                    "columnName": "sectionid",
                    "columnValue": '59'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }
   
    public async Get_FarmtoSchool(): Promise<any> {
        try {
            return new Promise((resolve, reject) => {
                const url = `${this.service.serviceUrl}/CMS_Containers/filter?andOr=AND&page=1&itemsPerPage=100`;
                let body = [{
                    "columnName": "pageid",
                    "columnValue": '19'
                },{
                    "columnName": "sectionid",
                    "columnValue": '60'
                }
              ]
                this.service.Http.post(url, body).pipe(
                    // tap(d => console.log('test',d)),
                    map((d: any) => (d.code === 1 ? d.document.records : false))
                ).subscribe((r: any) => resolve(r), reject);
            });
        } catch (error) {
            console.log(error);
        }
    }

}